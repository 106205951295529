/* textarea-field */
/* custom-hacky-form */

/* LAYOUT STYLING */

body {
    /* font-family: Courier; */
    font-family: 'Montserrat', sans-serif;
    /* letter-spacing: 1px; */
}

pre {
  color: white;
  background-color:black;
  padding: 10px 10px;
  border-radius: 5px;
}

/* .navigation-bar-custom {
  top: 0;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-left: 5%;
  margin-right: 5%;
  background-color: rgba(255, 255, 255, .7);
  font-family: Courier;
  border-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 3px  solid rgba(0,0,0,.03);
  } */

.inertia7-top-color {
  color: rgba(0,0,0,.80);
}  

.navigation-bar-custom a {
  color: #24515d;
}

.header-custom {
  text-align: center;
}

.trending-content-custom {
  margin-bottom: 200px;
}

.header-wordcloud-custom {
  /* text-align: center; */
  color:rgba(0,0,0,.9);
  /* margin-top: 45%; */
  margin-bottom: 30%;
}

.footer-custom {
  margin-top: 5%;
  padding: 5%;
  background-color: rgba(0,0,0,.03);
  color: white;
  text-align: center;
}

.footer-custom h5 {
  color: #24515d;
  font-weight: 700;
}

.footer-custom p {
  color: rgba(0,0,0,.7);
}


/* PROJECT STYLING */

.project-card-custom {
  height: 220px;
  padding: 20px;
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  /* background-color: rgba(56, 3, 83, 0.04); */
  /* background-color: rgba(56, 3, 83, 0.04); */
  background-color: rgba(0, 0, 0, 0.02);
  border-color: rgba(43, 2, 63, 0.1);
  /* margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 2px; */
  letter-spacing: -.3px;
  margin-bottom: 10px;
}
  
.zoom {
  transition: transform .2s; /*Animation*/
}

.zoom:hover {
  transform: scale(1.05);
  background-color: rgba(56, 3, 83, 0.00);
  box-shadow: 0px 2px 5px rgba(62, 17, 85, 0.4);
}

a:hover{
  text-decoration: none;
}

/* project card title */
.project-card-custom h3 {
  /* color: rbga(0,0,0,.5); */
  font-size: 1.25em;
  font-weight: 700;
}

.project-card-custom h5 {
  color: rgb(31, 31, 31);
  font-size: .7em;
}

.project-card-custom h6 {
  color: rgb(31, 31, 31);
  font-size: .6em;
}

.project-card-custom p {
  font-size: .6em;
}

.project-card-custom a {
  color: rgba(0,0,0,.9);
}

.project-detail-custom {
  padding-top: 50px;
}

.project-list-custom-billboard {
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 3%;
  /* background-color: rgba(0,0,0,.015); */
  color: rgba(0,0,0,.7));
  /* text-align: center; */
}

.project-detail-body-custom h1 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
}

.project-detail-body-custom h2 {
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 600;
}

.project-detail-body-custom h3, h4, h5, h6 {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 500;
}

.project-detail-body-custom p, li {
  font-weight: 500;
}

.project-detail-body-custom pre {
  padding-top: 4px;
  padding-bottom: 4px;
}

.project-detail-body-custom img {
  max-width: 100%;
}

.project-detail-author-custom {
  color: #24515d;
}

.project-create-edit-custom-container {
  padding-top: 50px;
  margin-right: 2%;
  margin-left: 2%;
}

.custom-editor-input {
  /* background-color: rgba(0,0,0,1); */
  padding: 20px;
  border-radius: 3px;
  color: black;
}

.custom-editor-render {
  /* background-color: rgba(0,0,0,.05); */
  padding: 20px;
  border-radius: 3px;
}

.project-create-form-title input {
  background-color: rgba(0,0,0,.9);
  color: #59c6c1;
  font-size: 1em;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 600;
}

.project-create-form-title label {
  margin-bottom: 0px;
  font-size: .8em;
  color: black;
}

.project-create-form-body textarea {
  margin-bottom: 20px;
  margin-top: 0px;
  font-size: 1em;
}

.project-create-form-body label {
  margin-bottom: 0px;
  font-size: 1em;
}

.project-create-form-title input[type=text] {
  color: #59c6c1;
}

.custom-hacky-form textarea {
  height: 60vh;
  font-size: 1.1em;
  font-weight: 600;
}

#title.form-control {
  color: #59c6c1;
  background-color: rgba(0,0,0,.9);
}
#subtitle.form-control {
  color: #59c6c1;
  background-color: rgba(0,0,0,.9);
}
#technology.form-control {
  color: #59c6c1;
  background-color: rgba(0,0,0,.9);
}
#skill.form-control {
  color: #59c6c1;
  background-color: rgba(0,0,0,.9);
}
#picture_link.form-control {
  color: #59c6c1;
  background-color: rgba(0,0,0,.9);
}
#body.form-control {
  color: #59c6c1;
  background-color: rgba(0,0,0,.9);
}




/* custom button */


button {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  top: 0;
  font-size: 1em;
  /* font-family: "Open Sans", Helvetica; */
  border-radius: 4px;
  border-bottom: 1px solid rgba( 0,0,0, 0.5 );
  background: #59c6c1;
  color: #fff;
  /* box-shadow: 0px 0px 0px rgba( 15, 165, 60, 0.1 ); */
  
  -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
          transform: translateZ(0);
  
  -webkit-transition: all 0.2s ease;
     -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

button:hover {
  top: -1px;
  /* box-shadow: 0px 10px 10px rgba( 15, 165, 60, 0.2 ); */
  
  /* -webkit-transform: rotateX(20deg);
     -moz-transform: rotateX(20deg);
      -ms-transform: rotateX(20deg);
          transform: rotateX(20deg); */
}

button:active {
  top: 0px;
  box-shadow: 0px 0px 0px rgba( 15, 165, 60, 0.0 );
  background: #24515d;
}

.custom-btn-save-cancel {
  margin: 0px 3px;
}

.create-button-custom {
  background-color: #59c6c1;
  /* border-color: white; */
  /* border-radius: 5px; */
  font-weight: bolder;
}

.create-update-project-button {
  margin-bottom: 15px;
}

.cancel-create-update-project-button {
  background: #8d6078;
}

.createEdit-project-title-render {
  font-size: 3em;
}

.createEdit-project-subtitle-render {
  font-size: 1em;
}

.new-project-header {
  color:#59c6c1;

}

.createEdit-project-body-render {
  height: 70vh;
  overflow: scroll;
}

.createEdit-project-body-render p, li {
  color: rgba(0,0,0,.7);
  font-weight: 400;
}

.createEdit-project-body-render pre {
  font-size: 1em;
  /* font-weight: bold; */
  color: white;
  background-color: rgba(0,0,0,.85);
}

.createEdit-project-preview-render {
  color: #24515d;
  border-style: solid;
  font-weight: bolder;
  /* background-color: rgba(255,255,255,.95); */
  text-align: center;
  margin-bottom: 10px;
}

.project-dangerous-section {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}


.project-detail-edit button {
  background: white;
  margin-top: 100px;
  color: #59c6c1;
  border: 1px solid #59c6c1;
  box-shadow: 0px 0px 0px black;

}

.project-dangerous-section button {
  background: white;
  margin-top: 100px;
  color: #d7066f;
  border: 1px solid #d7066f;
  box-shadow: 0px 0px 0px black;

}

#wordcloud-custom {
  fill:black;
}

@media screen and (max-width: 600px) {
  .word-cloud-custom-container {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.signup-custom {
  padding-top: 50px;
}

.user-home-custom {
  margin-top: 120px;
}



.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #59c6c1;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #59c6c1;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #24515d;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #24515d;
  -moz-box-shadow: 0px 0px 3px 2px #24515d;
  box-shadow: 0px 0px 2px 3px #24515d;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #24515d;
  -moz-box-shadow: 0px 0px 5px 5px #24515d;
  box-shadow: 0px 0px 5px 5px #24515d;
}


/* header */

.header {
  background-color: rgba(255,255,255, .90);
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 1000px) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

.pretty-button:hover {
  color: rgb(89, 198, 193);
  background-color: rgba(0,0,0,.05);
}